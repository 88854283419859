<template>
  <div class="card">
    <h3 class="card-title">LISTAR GERENCIAS</h3>
    <div v-if="gerencias">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="gerencias"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="showGerencia"
      ></table-list>
    </div>
    <modal v-if="openNew" @close="openNew = false">
      <nueva-gerencia slot="modal-body" @saved="refresh"></nueva-gerencia>
    </modal>
  </div>
</template>
<script>
import { GerenciaServices } from "../services/GerenciaServices";
import TableList from "@/components/elements/TableList";
import Modal from "@/components/elements/Modal";
import NuevaGerencia from "./NuevaGerencia.vue";
export default {
  components: { TableList, Modal, NuevaGerencia },
  name: "ListarGerencias",
  data() {
    return {
      gerencias: null,
      headers: GerenciaServices.headers(),
      body: GerenciaServices.body(),
      actionButton: {
        text: "Nueva Gerencia",
        action: () => {
          this.openNew = true;
        },
      },
      openNew: false,
    };
  },
  mounted() {
    GerenciaServices.api.getAll().then((gerencias) => (this.gerencias = gerencias));
  },
  methods: {
    refresh() {
      this.openNew = false;
      GerenciaServices.api.getAll().then((gerencias) => {
        this.gerencias = gerencias;
        this.$refs['tablelist'].refresh(gerencias)
      });
    },
    showGerencia(gerencia){
      this.$router.push({name:'VerGerencia', params:{id:gerencia.id}})
    }
  },
};
</script>