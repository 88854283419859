<template>
  <div class="modal-form">
    <h3 class="card-title">Cargar gerencia</h3>
    <form @submit.prevent="save">
      <pd-input
        v-model="descripcion"
        id="descripcion"
        label="Nombre"
        placeholder="Ingrese el nombre de la gerencia"
        class="my-1"
        :required="enviado && !$v.descripcion.required"
      ></pd-input>
      <suggest
        id="gerente"
        label="Gerente"
        placeholder="Buscar gerente.."
        class="my-1"
        :data="personas"
        :items="items"
        @selected="gerenteSelected"
        :required="enviado && !gerente"
      ></suggest>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import Suggest from "@/components/elements/Suggest";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import { GerenciaServices } from "../services/GerenciaServices";
import Swal from "sweetalert2";
export default {
  name: "NuevaGerencia",
  data() {
    return {
      personas: null,
      descripcion: "",
      gerente: null,
      items: PersonasService.dataList(),
      enviado: false
    };
  },
  components: {
    Suggest,
  },
  validations:{
    ...GerenciaServices.validations
  },
  mounted() {
    PersonasService.getAllForSelect().then((personas) => (this.personas = personas));
  },
  methods: {
    gerenteSelected(gerente) {
      this.gerente = gerente;
    },
    save() {
      this.enviado = true;
      if (!this.descripcion || !this.gerente) {
        return false;
      }
      GerenciaServices.api.save({
        descripcion: this.descripcion,
        gerente_id: this.gerente.id,
      }).then((r) => {
        Swal.fire("Éxito", `<p><b>${r.gerencia.descripcion}</b> se ha guardado con éxito</p>`, 'success');
        this.$emit('saved')
      })
      .catch((e) => {
        Swal.fire("Error", `<p>${e}</p>`, 'error');
      });
    },
  },
};
</script>